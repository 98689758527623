import { addDoc, collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import firebaseUtil from "firebaseUtil";

// const rootCollectionPath = "stripe/teste/";
const rootCollectionPath = "stripe/producao/";
export function initialize(callback) { }

export function getProducts() {
    return new Promise((resolve, reject) => {
        const productsRef = collection(firebaseUtil.db, rootCollectionPath + "products");
        const q = query(productsRef, where("active", "==", true));
        getDocs(q).then(async (querySnapshotP) => {
            let products = [];
            for (let index = 0; index < querySnapshotP.docs.length; index++) {
                const doc = querySnapshotP.docs[index];

                // querySnapshot.forEach(async (doc) => {
                let productView = { prices: [] };
                productView.original = doc.data();
                productView.uid = doc.id;
                productView.title = productView.original.name;
                productView.description = productView.original.description;
                productView.canPurchase = true;
                productView.typePayment = "STRIPE";

                const pricesRef = collection(firebaseUtil.db, doc.ref.path + "/prices");
                const querySnapshot = await getDocs(query(pricesRef, where("active", "==", true)));

                querySnapshot.forEach((doc) => {
                    let priceView = {};
                    priceView.original = doc.data();
                    priceView.uid = doc.id;
                    priceView.interval_count = priceView.original.interval_count;
                    priceView.intervalType = priceView.original.interval;
                    priceView.priceLong = priceView.original.unit_amount;

                    priceView.typePayment = "STRIPE";
                    productView.prices.push(priceView);
                });
                products.push(productView);
            }
            resolve(products);
        });
    });
}

export function getProductsOwned(userUid, products) {
    return new Promise((resolve, reject) => {
        const productsRef = collection(firebaseUtil.db, rootCollectionPath + "customers/" + userUid + "/subscriptions");
        const q = query(productsRef, where("status", "in", ["trialing", "active"]));
        getDocs(q).then(async (querySnapshotP) => {
            let productsOwned = [];
            for (let index = 0; index < querySnapshotP.docs.length; index++) {
                const doc = querySnapshotP.docs[index];
                let sub = doc.data();
                let uidProc = sub.product.id;
                let p = products.find((p) => p.uid === uidProc);
                let uidPrice = sub?.items[0]?.plan?.id
                let priceItself = sub?.items[0]?.plan

                let productView = { prices: [] };
                productView.original = sub;
                productView.originalprod = p;
                productView.uid = uidProc;
                productView.title = p.title;
                productView.description = p.description;
                productView.canPurchase = false;
                productView.typePayment = "STRIPE";
                productView.uidPrice = uidPrice
                productView.priceItself = priceItself
                productView.priceLong = priceItself.amount
                productView.interval_count = priceItself.interval_count
                productView.intervalType = priceItself.interval

                //     const pricesRef = collection(firebaseUtil.db, doc.ref.path + "/prices");
                //     const querySnapshot = await getDocs(query(pricesRef, where("active", "==", true)));

                //     querySnapshot.forEach((doc) => {
                //         let priceView = {};
                //         priceView.original = doc.data();
                //         priceView.uid = doc.id;
                //         priceView.interval_count = priceView.original.interval_count;
                //         priceView.intervalType = priceView.original.interval;
                //         priceView.priceLong = priceView.original.unit_amount;

                //         priceView.typePayment = "STRIPE";
                //         productView.prices.push(priceView);
                //     });
                productsOwned.push(productView);
            }
            resolve(productsOwned);
        });
    });
}

export function buyProductPrice(price, userUid, setLoading, urlReturn) {
    const checkout_sessionsRef = collection(
        firebaseUtil.db,
        rootCollectionPath + "customers/" + userUid + "/checkout_sessions"
    );

    const startCheckOut = { price: price.uid, success_url: urlReturn, cancel_url: urlReturn };
    setLoading(true);
    const checkoutRef = addDoc(checkout_sessionsRef, startCheckOut);

    checkoutRef.then((docRef) => {
        onSnapshot(
            docRef,
            (docSnapshot) => {
                const { error, url } = docSnapshot.data();
                if (error) {
                    // Show an error to your customer and
                    // inspect your Cloud Function logs in the Firebase console.
                    console.log(`An error occured: ${error.message}`);
                }
                if (url) {
                    // We have a Stripe Checkout URL, let's redirect.
                    window.location.assign(url);
                }
            },
            (err) => { }
        );
    });
}
